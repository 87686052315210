import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Dhruv Bhati </span>
            from <span className="purple"> Jodhpur, India.</span>
            <br />I am undergrad student in Data Science & Artificial
            Intelligence at IIIT Naya Raipur.
            <br />
            <br />
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Sketching
            </li>
            <li className="about-activity">
              <ImPointRight /> Paticipate in debate events
            </li>
            <li className="about-activity">
              <ImPointRight /> Badminton
            </li>
            <li className="about-activity">
              <ImPointRight /> Travelling
            </li>
          </ul>

          <p
            style={{
              color: "rgb(155 126 172)",
              marginTop: "4rem",
              marginBottom: "0",
            }}
          >
            "Believe in yourself, be persistent, be consistent, be patient,{" "}
          </p>
          <p style={{ color: "rgb(155 126 172)", marginTop: "0" }}>
            time will come when it will finally come to you!"{" "}
          </p>
          <footer className="blockquote-footer">Dhruv</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
