import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/leaf.png";
import emotion from "../../Assets/Projects/emotion.png";
import editor from "../../Assets/Projects/codeEditor.png";
import vennchat from "../../Assets/Projects/vennchat.png";
import biometric from "../../Assets/Projects/biometric.png";
import fer2013 from "../../Assets/Projects/fer2013.png";
import todo from "../../Assets/Projects/todo.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={vennchat}
              isBlog={false}
              title="VennChat"
              description="A Fully functional Social Media website using MERN stack with responsive UI/UX. Implemented user authentication with JWT token Hosted on MongoDB Atlas.Key Features: Authentication, Following,Unfollowing,Timeline Feed, Like & share"
              ghLink="https://github.com/ne0-d/social-media-app"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={todo}
              isBlog={false}
              title="Task-It: A Todolist-app"
              description="A cloud-based to-do list app that can help you list your daily tasks. You can form multiple to-do lists using different routes All to-do list details are saved in
              the database."
              ghLink="https://github.com/ne0-d/todolist-app"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={biometric}
              isBlog={false}
              title="MGNREGA-Auth"
              description="Created a biometric attendance system that uses a fingerprint sensor to mark attendance
              for daily wage workers like in MGNREGA. Has the functionality to register, mark attendance, and track the time between workers working for each individual This was achieved using Google Scripts.
              "
              ghLink="https://github.com/ne0-d/IoT-Based-Biometric-Attendance-with-Payment-System"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
