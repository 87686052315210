import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "./Particle";
import Card from "react-bootstrap/Card";

import infographic from "../Assets/infographic.png";

function Infographic() {
  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My<strong className="purple"> Creative</strong> Section
        </h1>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={8} className="project-card">
            <Card className="project-card-view">
              <Card.Img
                variant="top"
                src={infographic}
                alt="card-img"
                style={{ filter: "brightness(120%)" }}
              />
            </Card>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Infographic;
