import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";
import debate from "../../Assets/me22.jpg";
import eren from "../../Assets/eren.webp";
import trunks from "../../Assets/trunks.webp";
import ironman from "../../Assets/ironman.webp";
import batman from "../../Assets/batman.webp";
import flash from "../../Assets/flash.webp";
import goku from "../../Assets/goku.webp";
import krishna from "../../Assets/krishna.jpg";

function Creative() {
  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My<strong className="purple"> Creative</strong> Section
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              <strong className="purple">Debating</strong> Experience
            </h1>
            <Card className="quote-card-view">
              <Card.Body>
                <blockquote className="blockquote mb-0">
                  <p style={{ textAlign: "justify" }}>
                    I feel really excited about discussing about new topic with
                    people, this is the reason I like{" "}
                    <span className="purple">Debating </span>.
                    <br />
                    <br />
                    Here are a few experience in debating I have till now.
                  </p>
                  <ul>
                    <li className="about-activity">
                      <ImPointRight /> Represented college Fr. CRMD 2021, an
                      national level debate competition, and made it till
                      semi-finals.
                    </li>
                    <li className="about-activity">
                      <ImPointRight /> Former core member of college's debating
                      club, Take Da Bait.
                    </li>
                    <li className="about-activity">
                      <ImPointRight /> Organised various online and offline
                      debates in college.
                    </li>
                  </ul>
                </blockquote>
              </Card.Body>
            </Card>
          </Col>
          <Col
            md={5}
            style={{ paddingTop: "120px", paddingBottom: "50px" }}
            className="about-img"
          >
            <img
              src={debate}
              alt="about"
              className="img-fluid"
              style={{
                width: "300px",
                height: "350px",
                objectFit: "cover",
                // overflowY: "hidden",
                filter: "grayscale(100%)",
              }}
            />
          </Col>
        </Row>
        <h1 className="project-heading">
          Sketches <strong className="purple">& </strong>Art
        </h1>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={8} className="project-card">
            <Card className="project-card-view">
              <Card.Img variant="top" src={krishna} alt="card-img" />
              <Card.Body>
                <Card.Title>Shri Krishna</Card.Title>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <Card className="project-card-view">
              <Card.Img variant="top" src={eren} alt="card-img" />
              <Card.Body>
                <Card.Title>Eren Yeager</Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="project-card">
            <Card className="project-card-view">
              <Card.Img variant="top" src={goku} alt="card-img" />
              <Card.Body>
                <Card.Title>Goku</Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="project-card">
            <Card className="project-card-view">
              <Card.Img variant="top" src={ironman} alt="card-img" />
              <Card.Body>
                <Card.Title>Iron-Man</Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="project-card">
            <Card className="project-card-view">
              <Card.Img variant="top" src={trunks} alt="card-img" />
              <Card.Body>
                <Card.Title>Trunks</Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="project-card">
            <Card className="project-card-view">
              <Card.Img variant="top" src={flash} alt="card-img" />
              <Card.Body>
                <Card.Title>The Flash</Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="project-card">
            <Card className="project-card-view">
              <Card.Img variant="top" src={batman} alt="card-img" />
              <Card.Body>
                <Card.Title>Batman</Card.Title>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Creative;
